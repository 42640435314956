<template>
    <b-form @submit.stop.prevent="onSubmit">
        <h1 class="h3 mb-3 fw-normal">
            Admin Sign In
        </h1>
        <div class="form-control-wrapper">
            <b-form-input
                v-model="email"
                type="text"
                class="form-control"
                placeholder="Email"
                required
                autocomplete="off"
                autocapitalize="none"
                autocorrect="off"
            />
        </div>

        <div class="form-control-wrapper">
            <b-form-input
                v-if="!isForgotPassword"
                v-model="password"
                type="password"
                class="form-control"
                placeholder="Password"
                required
                autocomplete="off"
                autocapitalize="none"
                autocorrect="off"
            />
        </div>

        <div class="form-control-wrapper" style="margin: 1rem 0;" v-if="!isForgotPassword">
            <b-form-checkbox
              id="checkbox-1"
              v-model="remember_me"
              name="checkbox-1"
            >
              Remember me
            </b-form-checkbox>
        </div>

        <button
            type="button"
            class="w-100 btn btn-lg btn-primary"
            @click="submitForgotPassword"
            v-if="isForgotPassword"
        >
            Submit
        </button>

        <button
            v-else
            class="w-100 btn btn-lg btn-primary"
            type="submit"
        >
            Sign in
        </button>

        <p class="forgot-password" v-if="isForgotPassword">
            Log in?
            <a @click.prevent="signInForm" style="color: #2c4f91; cursor: pointer;">Click Here</a>
        </p>
        <p class="forgot-password" v-else>
            Forgot your password?
            <a @click.prevent="forgotYourPassword()" style="color: #2c4f91; cursor: pointer;">Click Here</a>
        </p>

        <p class="mt-5 mb-3 text-muted">
            &copy; 2021
        </p>
    </b-form>
</template>

<script>
import { mapActions } from "vuex"

export default {
    data () {
        return {
            isForgotPassword: false,
            email: null,
            password: null,
            passwordConfirmation: null,
            currentPassword: null,
            remember_me: false
        }
    },

    mounted () {
        this.$store.dispatch("utils/changeBodyClass", { add: ["admin-signin"] })
    },

    destroyed () {
        this.$store.dispatch("utils/changeBodyClass", { remove: ["admin-signin"] })
    },

    methods: {
        ...mapActions("account", ["adminSignin", "adminSendResetPassword"]),

        onSubmit () {
            this.adminSignin({ email: this.email, password: this.password })
                .then(() => {
                    this.$router.push({
                    
                        path: "/admin/heroes"

                    })
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                    this.errorMessage = error.message
                })
        },

        submitForgotPassword () {
            var validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(validEmailRegex.test(this.email)){
                this.adminSendResetPassword({
                    email: this.email
                    }).then(() => {
                        this.$bvToast.toast("Please find the reset password email in your inbox!", {
                            title: "Email send!",
                            variant: "success",
                            autoHideDelay: 5000
                        });
                        window.location.reload();
                    }).catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
            }else{
                this.$bvToast.toast("Invalid email format", {
                    title: "Unable to save",
                    variant: "danger",
                    autoHideDelay: 5000
                })
            }
        },
        forgotYourPassword () {
            this.isForgotPassword = true;
        },

        signInForm () {
            this.isForgotPassword = false;
        },
    }
}
</script>

<style lang="scss">
.admin-signin {
    height: 100%;
    // background-color: #f5f5f5 !important;
}
</style>

<style lang="scss" scoped>
.form-control-wrapper {
    &:focus-within {
        z-index: 2;
    }
}

input[type="text"] {
  margin-bottom: 10px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>

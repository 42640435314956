<template>
    <b-modal
        :id="id"
        hide-footer
        :title="header"
        size="sm"
    >
                    {{title}}
    </b-modal>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: true,
            validator: value => [
                "alertBeforeSubscriber",
            ].indexOf(value) !== -1
        },
        id: {
            type: String,
            default: "alert_before_subscriber_modal"
        }
    },

    data () {
        return {
            titles: {
                alertBeforeSubscriber: "You must be a Member to use this feature",
            },
            headers: {
                alertBeforeSubscriber: "Favorites"  
            }
        }
    },

    computed: {
        title () { return this.titles[this.type] },
        header () { return this.headers[this.type] },
    },

    methods: {
        onLoginClicked () {
            this.$bvModal.hide(this.id)
            this.$bvModal.show("signin_modal")
        },

        onSubscription () {
            this.$bvModal.hide(this.id)
            this.$router.push({ name: "choose-plan" })
        },

        onClose () {
            this.$bvModal.hide(this.id)
        },
    },
}
</script>

<style lang="scss">
    .subscribe-modal {
        .modal-body {
            padding: 0;
        }

        .container-fluid {
            padding: 0;
        }
    }
</style>

<template>
    <header class="header bg-white box-shadow">
        <b-navbar
            toggleable="md"
            type="light"
            class="main-navbar border-bottom px-md-3"
        >
            <b-navbar-brand to="/">
                design<strong>guide</strong>
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse" />

            <b-collapse
                id="nav-collapse"
                is-nav
            >
                <b-navbar-nav class="mr-md-3" fill>
                    <b-dropdown-hover
                        text="Products"
                        no-caret
                        page-to-go="/building-products"
                        :scrollable="true"
                    >
                        <b-dropdown-item
                            v-for="(division, divisionIndex) in topDivisions"
                            :key="divisionIndex"
                            :to="{ name: 'product-division-page', params:{division: division.slug } }"
                        >
                            {{ division.code.substring(0, 2) }} 
                            {{ division.title }}
                        </b-dropdown-item>
                    </b-dropdown-hover>

                    <b-dropdown-hover
                        text="Professionals"
                        no-caret
                        page-to-go="/building-design-firms"
                    >
                        <b-dropdown-item
                            :to="{ name: 'design-firms-architects' }"
                        >
                            Architecture
                        </b-dropdown-item>

                        <b-dropdown-item
                            :to="{ name: 'design-firms-engineers' }"
                        >
                            Engineering
                        </b-dropdown-item>

                        <b-dropdown-item
                            :to="{ name: 'design-firms-landscape-architects' }"
                        >
                            Landscape Architecture
                        </b-dropdown-item>

                        <b-dropdown-item
                            :to="{ name: 'design-firms-interior-designers' }"
                        >
                            Interior&nbsp;Design
                        </b-dropdown-item>
                        <b-dropdown-item
                            :to="{ name: 'design-firms-acoustical-theater' }"
                        >
                            Audio-Visual&nbsp;&&nbsp;Theater
                        </b-dropdown-item>
                        <b-dropdown-item
                            :to="{ name: 'design-firms-lighting-designers' }"
                        >
                            Lighting&nbsp;Design
                        </b-dropdown-item>
                    </b-dropdown-hover>

                    <b-dropdown-hover
                        text="News"
                        no-caret
                        page-to-go="/aec-news"
                    >
                        <b-dropdown-item
                            :to="{ name: 'industry-news' }"
                        >
                            Industry&nbsp;News
                        </b-dropdown-item>
<!--                         <b-dropdown-item
                            :to="{ name: 'news' }"
                        >
                            News
                        </b-dropdown-item> -->

                        <b-dropdown-item
                            :to="{ name: 'events' }"
                        >
                            Events&nbsp;+&nbsp;Expos
                        </b-dropdown-item>

                        <b-dropdown-item to="/building-product-showcase">
                            Product&nbsp;Showcase
                        </b-dropdown-item>

                        <b-dropdown-item
                            :to="{ name: 'learning-center' }"
                        >
                            CEU&nbsp;Learning&nbsp;Center
                        </b-dropdown-item>

                        <b-dropdown-item
                            :to="{ name: 'videos' }"
                        >
                            Video&nbsp;Archive
                        </b-dropdown-item>
                    </b-dropdown-hover>

                    <b-nav-item href="#" @click.stop.prevent="searchMode = !searchMode">
                        <b-img
                            :src="require('assets/images/search.png')"
                            width="22"
                            height="22"
                        />
                    </b-nav-item>
                </b-navbar-nav>

                <b-navbar-nav class="ml-auto text-center">
                    <b-nav-item
                        v-if="isAuthorized && currentUser.plan === 'basic'"
                        href="#"
                        @click.stop.prevent="onUpgrade"
                    >
                        Upgrade
                    </b-nav-item>
                    <template v-if="!isAuthorized">
                        <b-nav-item href="#" @click.stop.prevent="onFavorites">
                            Favorites
                        </b-nav-item>
                    </template>
                    <template v-if="isAuthorized && currentUser.plan === 'basic'">
                        <b-nav-item
                            link-classes="subscribe"
                            href="#"
                            @click.stop.prevent="onBasicUserFavorite"
                        >
                            Favorites
                        </b-nav-item>
                    </template>

                    <template v-if="isAuthorized">
                        <b-nav-item v-if="currentUser.plan != 'basic'" to="/favorites">
                            Favorites
                        </b-nav-item>

                        <portal-target name="header-additional-actions" />

                        <b-nav-item-dropdown text="Account" right>
                            <b-dropdown-text>{{ currentUser.email }}</b-dropdown-text>

                            <b-dropdown-divider />

                            <b-dropdown-item to="/settings/account">
                                Settings
                            </b-dropdown-item>

                            <b-dropdown-item to="/settings/applications/manufacturer" v-if="currentUser.manufacturer_profiles_count > 0">
                                Manufacturer Admin Settings
                            </b-dropdown-item>

                            <b-dropdown-item to="/settings/applications/firm" v-if="currentUser.firm_profiles_count > 0">
                                Firm Admin Settings
                            </b-dropdown-item>

                            <b-dropdown-item href="#" @click.stop.prevent="onSignOut">
                                Sign out
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                    </template>

                    <template v-else>
                        <b-nav-item
                            v-if="showBecomeAMember"
                            link-classes="subscribe"
                            href="#"
                            @click.stop.prevent="onSubscribe"
                        >
                            Become a Member
                        </b-nav-item>

                        <b-button
                            id="member-login"
                            class="ml-2"
                            variant="primary"
                            squared
                            @click.prevent.end="onSignIn"
                        >
                            {{showMemberLogin()}}
                        </b-button>
                    </template>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>

        <transition name="fade">
            <b-navbar
                v-show="searchMode"
                toggleable="lg"
                type="light"
                class="search-navbar border-bottom"
            >
                <b-img
                    :src="require('assets/images/search.png')"
                    width="25"
                    height="25"
                />

                <b-navbar-nav
                    class="mr-md-3 ml-auto"
                    fill
                    style="flex-grow: 1;"
                >
                    <template v-if="false">
                        <b-nav-text style="padding-top: 1.1rem;">
                            Select:
                        </b-nav-text>

                        <b-nav-item-dropdown
                            :text="selectTypeTitle"
                            class="navbar-select"
                            style="width: 180px; text-align: left; flex-grow: 0; flex-shrink: 0;"
                        >
                            <b-dropdown-item @click.stop.prevent="selectType = 'all'">
                                All
                            </b-dropdown-item>

                            <b-dropdown-item @click.stop.prevent="selectType = 'building_products'">
                                Building Products
                            </b-dropdown-item>

                            <b-dropdown-item @click.stop.prevent="selectType = 'design_firms'">
                                Design Firms
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                    </template>

                    <b-nav-text v-show="false" style="padding-top: 1.1rem;">
                        Search:
                    </b-nav-text>
                    <li class="nav-item b-nav-dropdown dropdown search-dropdown" style="flex-grow: 1; flex-shrink: 1; margin-top: 0.5rem; margin-bottom: 0.5rem;">
                        <form
                            action="/building-products"
                            class="form"
                            @submit="onSearch"
                        >
                            <b-form-input
                                ref="searchInput"
                                v-model="keyword"
                                type="search"
                                name="keyword"
                                size="md"
                                class="mr-sm-2 rounded-0 border-0 new-input-search"
                                placeholder="Search building products"
                                auto-focus="{true}"
                            />
                            <!-- <input type="hidden" name="page" value="1"> -->
                        </form>
                    </li>

                    <li
                        v-show="false"
                        class="nav-item b-nav-dropdown dropdown search-dropdown"
                        style="flex-grow: 1; flex-shrink: 1; margin-top: 0.5rem; margin-bottom: 0.5rem;"
                    >
                        <multiselect
                            id="search-input"
                            v-model="searchEntry"
                            label="type"
                            track-by="id"
                            :placeholder="selectTypePlaceholder"
                            open-direction="bottom"
                            :options="searchEntries"
                            :multiple="false"
                            :searchable="true"
                            :loading="isLoadingSearchEntries"
                            :internal-search="false"
                            :clear-on-select="true"
                            :close-on-select="true"
                            :options-limit="300"
                            :option-height="32"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="false"
                            @search-change="asyncFindSearchEntries"
                        >
                            <span
                                slot="noResult"
                            >
                                Oops! No elements found. Consider changing the search query.
                            </span>

                            <template
                                slot="singleLabel"
                                slot-scope="props"
                            >
                                <span class="option__desc">
                                    <span class="option__title">{{ props.option.attributes.company_name }}</span>
                                </span>
                            </template>

                            <template
                                slot="option"
                                slot-scope="props"
                            >
                                <div class="option__desc">
                                    <span class="option__very_small">
                                        {{ props.option.type === 'FirmProfile' ? 'FIRM' : 'MANUFACTURER' }}
                                    </span>
                                    <span
                                        class="option__title"
                                        v-html="props.option.highlight.company_name ? props.option.highlight.company_name[0] : props.option.attributes.company_name"
                                    />
                                    <span
                                        class="option__small"
                                        v-html="props.option.highlight.country ? props.option.highlight.country[0] : Array.isArray(props.option.attributes.country) ? props.option.attributes.country[0] : props.option.attributes.country"
                                    />
                                    <span
                                        class="option__small"
                                        v-html="props.option.highlight.state ? props.option.highlight.state[0] : Array.isArray(props.option.attributes.state) ? props.option.attributes.state[0] : props.option.attributes.state"
                                    />
                                    <span
                                        class="option__small"
                                        v-html="props.option.highlight.city ? props.option.highlight.city[0] : props.option.attributes.city"
                                    />
                                    <span
                                        class="option__small"
                                        v-html="props.option.highlight.address ? props.option.highlight.address[0] : props.option.attributes.address"
                                    />
                                    <span
                                        class="option__small"
                                        v-html="props.option.highlight.zip ? props.option.highlight.zip[0] : props.option.attributes.zip"
                                    />
                                    <span
                                        v-if="props.option.highlight.phone || props.option.attributes.phone"
                                        class="option__small"
                                        v-html="props.option.highlight.phone ? props.option.highlight.phone[0] : props.option.attributes.phone"
                                    />
                                    <span
                                        v-if="props.option.highlight.site || props.option.attributes.site"
                                        class="option__small"
                                        v-html="props.option.highlight.site ? props.option.highlight.site[0] : props.option.attributes.site"
                                    />
                                </div>
                            </template>
                        </multiselect>
                    </li>
                    <li style="margin-top:0.5rem">
                        <b-button
                            variant="primary"
                            class="rounded-0"
                            pill
                            @click.prevent="onSearch()"
                        >
                            Search
                        </b-button>
                    </li>

                    <!-- <b-nav-form>
                        <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
                    </b-nav-form> -->
                </b-navbar-nav>

                <b-button
                    variant="outline-secondary"
                    pill
                    style="padding: 0.05rem 0.2rem 0;"
                    @click.prevent="searchMode = false"
                >
                    <b-icon icon="x" />
                </b-button>

                <!-- <b-navbar-nav>
                    <b-button
                        variant="primary"
                        squared
                        @click.prevent="onSearch"
                    >
                        Search
                    </b-button>
                </b-navbar-nav> -->
            </b-navbar>
        </transition>

        <!-- <section class="d-flex flex-column flex-md-row align-items-center">
            <nav class="my-2 my-md-0 mr-md-auto">
                <span class="navbar-text">text</span>
                <a class="nav-link active" href="#">Active</a>
            </nav>
        </section> -->

        <!-- DEFAULT SUBSCRIBE MODAL: -->
        <become-subscriber-modal
            type="becomeSubscriber"
        />
        <become-subscriber-image-modal
            type="becomeSubscriberImage"
        />
        <basic-user-favorite-modal
            id="basic_user_favorite_modal"
            type="saveFavorites"
        />

        <become-member-modal
            type="becomeAMember"
        />

        <signin-modal
            type="visiting"
        />
        <signin-modal
            id="manufacturer_admin_modal"
            type="manufacturer_admin"
        />
        <signin-modal
            id="firm_admin_modal"
            type="firm_admin"
        />
        <favorite-modal
            id="favorite_modal"
            type="favorites"
        />
        <alert-before-subscribe-modal
            type="alertBeforeSubscriber"
        />
        <newsletter-subscription-modal
            type="newsletter"
        />
    </header>
</template>

<script>
import BecomeSubscriberImageModal from "modals/become-subscriber-image"
import NewsletterSubscriptionModal from "modals/newsletter-subscription"
import BecomeSubscriberModal from "modals/become-subscriber"
import BasicUserFavoriteModal from "modals/become-subscriber"
import AlertBeforeSubscribeModal from "modals/alert-before-subscribe"
import SigninModal from "modals/signin"
import BecomeMemberModal from "modals/become-member"
import FavoriteModal from "modals/signin"
import BDropdownHover from "../shared/b-dropdown-hover"
import { mapActions, mapGetters } from "vuex"
// import { suggestFirms } from "api/firms"
import Multiselect from "vue-multiselect"
import { search } from "api/search"

export default {
    components: {
        NewsletterSubscriptionModal,
        BecomeMemberModal,
        AlertBeforeSubscribeModal,
        BecomeSubscriberImageModal,
        BecomeSubscriberModal,
        BasicUserFavoriteModal,
        SigninModal,
        FavoriteModal,
        Multiselect,
        BDropdownHover,
    },

    data () {
        const urlParams = new URLSearchParams(window.location.search)
        const keywordParam = urlParams.get("keyword")
        return {
            showBecomeAMember: false,
            searchMode: false,
            keyword: keywordParam,
            page: 1,
            // searchQuery: "",
            selectType: "building_products",
            selectTypes: {
                all: "All",
                building_products: "Building Products",
                design_firms: "Design Firms"
            },
            searchEntry: null,
            searchEntries: [],
            isLoadingSearchEntries: false,
            timeoutId: null,
        }
    },

    computed: {
        ...mapGetters("account", ["isAuthorized", "currentUser"]),
        ...mapGetters("manufacturers",["topDivisions"]),

        selectTypeTitle () {
            return this.selectTypes[this.selectType]
        },

        selectTypePlaceholder () {
            return this.selectType === "building_products" ? "Search building products" :
                this.selectType === "design_firms" ? "Enter Keyword or Location" : "Type to search..."
        },
    },

    watch: {
        //"searchEntry": "navigateToEntry"
        "searchMode": "searchInputAutoFocus"
    },
    mounted () {
        this.loadTopDivisions();
        this.$root.$on('show-become-a-member-modal', (whoIsWho) => {
            this.showBecomeAMember = true;
        });

        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
          if (modalId === 'alert_before_subscriber_modal') {
            this.clearModalTimeout();
          }
        });
    },

    methods: {
        ...mapActions("account", ["signout", "upgrade"]),
        ...mapActions("manufacturers", ["loadTopDivisions"]),

        showMemberLogin () {
            if(this.$route.path.includes('/application/firm')){
                return 'Firm Admin Login'
            }else if (this.$route.path.includes('/application/manufacturer')){
                return 'Manufacturer Admin Login'
            }else{
                return 'Member Log In'
            }
        },

        onSignIn () {
            if(this.$route.path.includes('/application/firm')){
                this.$bvModal.show("firm_admin_modal")
            }else if (this.$route.path.includes('/application/manufacturer')){
                this.$bvModal.show("manufacturer_admin_modal")
            }else{
                this.$bvModal.show("signin_modal")
            }
        },

        onFavorites () {
            this.$bvModal.show("alert_before_subscriber_modal")
            let _this_ = this
            this.timeoutId = setTimeout(()=>{
                _this_.$bvModal.hide("alert_before_subscriber_modal")
                _this_.$bvModal.show('become_subscriber_modal')
            },3000)
        },

        onSubscribe () {
            // this.$router.push({ name: "become-a-member" });
            // console.log('onSubscribe')
            this.$bvModal.show("become_a_member");
        },

        onBasicUserFavorite () {
            this.$bvModal.show("basic_user_favorite_modal")
        },

        onSignOut () {
            this.signout()
                .then(() => {
                    this.$forceUpdate();
                    this.$router.push({ name: "home" });
                })
        },

        onSearch () {
            switch (this.selectType) {
            case "building_products":
                this.searchBuildingProducts()
                break
            case "design_firms":
                this.searchDesignFirms()
                break
            default:
                break
            }
            //
        },

        searchBuildingProducts () {
            this.$router.push({
                name: "products-directory-index",
                query: {
                    keyword: this.keyword,
                    page: this.page,
                    country: "us"
                }
            })
        },

        searchDesignFirms () {
            this.$router.push({
                name: "design-firms",
                query: {
                    keyword: this.searchQuery,
                    country: "us"
                }
            })
            // suggestFirms(this.searchQuery)
            //     .then(data => {})
            //     .catch(error => {})
        },

        asyncFindSearchEntries (query) {
            this.isLoadingSearchEntries = true
            const type = this.selectType === "building_products" ? "manufacturer" : this.selectType === "design_firms" ? "firm" : undefined
            search({ type, query })
                .then(result => {
                    this.searchEntries.splice(0, this.searchEntries.length, ...result)
                    this.isLoadingSearchEntries = false
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                    this.isLoadingSearchEntries = false
                })
        },

        onUpgrade () {            
            this.upgrade(
            ).then(() => {
            }).catch(error => {
                this.$bvToast.toast(error.message, {
                    title: "Error",
                    variant: "danger",
                    autoHideDelay: 5000
                })
            })
        },

        navigateToEntry (newValue) {
            if (newValue) {
                // alert(newValue)
                if (newValue.type === "FirmProfile") {
                    this.$router.push({
                        name: "design-firms",
                        query: {
                            page: 1,
                            keyword: newValue.company_name,
                            country: "us"
                        }
                    })
                } else if (newValue.type === "ManufacturerProfile") {
                    this.$router.push({
                        name: 'product-page',
                        params: { id: newValue.id }
                    })
                }
                this.searchEntry = null
                this.searchMode = false
            }
        },
        searchInputAutoFocus (newValue) {
            if(newValue){
                const input = this.$refs.searchInput
                const end = input.value.length

                input.setSelectionRange(end, end)
                this.$nextTick(() => input.focus())
            }
            
        },
        clearModalTimeout() {
          if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
            console.log('Timeout cleared');
          }
        },
    },
}
</script>

<style lang="scss">
    
html, body {
  height: 100vh;
}

#app {
    padding-top: 2.6rem;

    .manufacturers, .firms, .events, .videos, .settings-main, .settings-page, .favorites, .product-profile{
      margin-top: 2rem;
    }

    &.admin {
        padding-top: 0;
    }
}

.highlight {
    font-weight: bold;
}
.option__very_small {
    font-size: 10px;
}
.option__small {
    font-size: 11px;
}
.navbar-select {
    .nav-link {
        margin-top: 5px !important;
    }
}
#search-input.multiselect__input{
    border-radius:0 !important;
}
.multiselect__content-wrapper {
    border-bottom-left-radius:0 !important;
    border-bottom-right-radius:0 !important;
}
</style>

<style lang="scss" scoped>
.main-navbar,
.search-navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
}

.search-navbar {
    top: 3.5rem;
    z-index: 998;
}

.search-dropdown {
    /deep/.multiselect {
        &__input {
            font-size: 14px;
        }
        &__placeholder {
            font-size: 14px;
        }
        &__option--highlight {
            background: #225a90 !important;
            .highlight {
                color: #fff;
            }
        }
        &__option--highlight:after {
            background: #225a90 !important;
        }
    }
}

#member-login {
  text-transform: none;
}

@media (min-width: 992px) {
    .navbar-nav > li {
      margin-left: 15px;
      margin-right: 15px;
    }
}
.new-input-search{
    border-left: 3px solid #ededed !important;
    font-size: 18px;
    &:focus{
        outline: none;
        box-shadow: none;
    }
}

</style>

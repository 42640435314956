    <template>
    <div class="content">
        <h1>Manufacturers</h1>

        <b-tabs
            v-model="currentTab"
            content-class="mt-3"
        >
            <b-tab :title="allTab" active />
            <b-tab :title="applicationsTab" />
            <b-tab :title="preApprovedTab" />
            <b-tab :title="submittedTab" />
            <b-tab :title="publishedTab" />
            <b-tab :title="deniedTab" />
            <b-tab :title="claimedTab" />
            <b-tab :title="unClaimedTab" />
        </b-tabs>

        <b-row>
            <b-col>
                <b-button-toolbar
                    v-if="currentTab === 0"
                    aria-label="Actions"
                    key-nav
                    class="mb-3"
                >
                    <b-button-group class="mx-1">
                        <b-button
                            :to="{ name: 'admin-new-manufacturer' }"
                        >
                            New Manufacturer Profile
                        </b-button>
                    </b-button-group>
                </b-button-toolbar>
            </b-col>
            <b-col class="d-flex justify-content-end">
                <b-pagination-nav
                    v-model="currentPage"
                    :number-of-pages="lastPage"
                    :limit="7"
                    :to="{ name: 'admin-manufacturers'}"
                    :disabled="paginationDisable"
                    @page-click="onPageClick"
                />
            </b-col>
        </b-row>

        <b-table
            id="index-table"
            :fields="fields"
            :items="items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter-debounce="1000"
            primary-key="id"
            stacked="md"
            show-empty
            small
            striped
            hover
            @row-clicked="onClickRow"
            :busy.sync="paginationDisable"
        >
            <template #table-busy>
                <div class="text-center my-2" style="color: #343a40;">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>
            <template #cell(count)="row">
                {{ (row.index + 1) + (currentPage ? (currentPage - 1) * itemsPerPage : 0 ) }}
            </template>

            <template #cell(company_name)="row">
                <router-link
                    :to="{ name: 'product-page', params: { id: row.item.id }}"
                    target="_blank"
                >
                    {{ row.item.company_name }}
                </router-link>
            </template>

            <template #cell(site)="row">
                <a
                    :href="row.item.site | url"
                    target="_blank"
                >
                    {{ row.item.site }}
                </a>
            </template>

<!--             <template #cell(country)="row">
                {{ row.item.country | country }}
            </template> -->

<!--             <template #cell(state)="row">
                {{ row.item.state | region(row.item.country) }}
            </template> -->

<!--             <template #cell(status)="row">
                {{ row.item.profile_state }}
            </template> -->

            <template #cell(created_at)="row">
                {{ row.item.created_at | date }}
            </template>

            <template #cell(action_date)="row">
                {{ getActionDate(row) | date }}
            </template>

            <template #cell(updated_at)="row">
                {{ row.item.updated_at | date }}
            </template>

            <template #cell(approved_at)="row">
                {{ row.item.approved_at | date }}
            </template>

            <template #cell(live_at)="row">
                {{ row.item.live_at | date }}
            </template>

            <template #cell(approval)="row">
                <b-button
                    size="sm"
                    variant="success"
                    @click.prevent="onApprove(row.item)"
                >
                    Approve
                </b-button>

                <b-button
                    size="sm"
                    variant="danger"
                    @click.prevent="onReportProfile(row.item.id)"
                >
                    Deny
                </b-button>

                <b-button
                    size="sm"
                    variant="danger"
                    @click.prevent="onDelete(row.item)"
                    v-if="approvalStates[currentTab] != 'submitted'"
                >
                    Delete
                </b-button>

                <b-button
                    size="sm"
                    variant="warning"
                    @click.prevent="onCommentProfile(row.item.id)"
                    v-if="approvalStates[currentTab] == 'submitted'"
                >
                    Comment
                </b-button>
            </template>
        </b-table>
        <div class="d-flex justify-content-end">
            <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="lastPage"
                :limit="7"
                :to="{ name: 'admin-manufacturers'}"
                :disabled="paginationDisable"
                @page-click="onPageClick"
            />
        </div>

        <b-modal
            id="denied_reason_modal"
            title="Denial Reasons"
            size="lg"
        >
            <b-form @submit.stop.prevent="onSubmitNewReport">
                <b-form-group label="Select report topic:" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="newReport.topics"
                    :options="[{
                        text: 'Your Company is not located in USA or Canada', value: 'Your Company is not located in USA or Canada', disabled: !!newReport.id
                    },{
                        text: 'Your Company does not provide Building Products types that we cover in the Library', value: 'Your Company does not provide Building Products types that we cover in the Library', disabled: !!newReport.id
                    },{
                        text: 'Your available media or website is too limited', value: 'Your available media or website is too limited', disabled: !!newReport.id
                    }]"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                    stacked
                  ></b-form-checkbox-group>
                </b-form-group>

<!--                 <b-form-group
                    label="Comment Box:"
                    label-for="new_review_body"
                    :state="newReport.bodyState"
                >
                    <b-form-textarea
                        v-model.trim="newReport.body"
                        type="text"
                        placeholder="Write your comment..."
                        rows="6"
                        max-rows="6"
                        :disabled="!!newReport.id"
                    />
                </b-form-group> -->
            </b-form>

            <template #modal-footer>
                <b-button
                    variant="primary"
                    @click="onSubmitNewReport"
                    :disabled="!!newReport.id"
                >
                    Submit report
                </b-button>

                <b-button
                    variant="secondary"
                    @click="$bvModal.hide('denied_reason_modal')"
                >
                    Cancel
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="comment_profile_modal"
            title="Add Comment"
            size="lg"
        >
            <b-form @submit.stop.prevent="onSubmitNewComment">
                <b-form-group
                    label="Comment Box:"
                    label-for="new_review_body"
                    :state="newReport.bodyState"
                >
                    <b-form-textarea
                        v-model="newReport.body"
                        type="text"
                        placeholder="Write your comment..."
                        rows="6"
                        max-rows="6"
                        :disabled="!!newReport.id"
                    />
                </b-form-group>
            </b-form>

            <template #modal-footer>
                <b-button
                    variant="primary"
                    @click="onSubmitNewComment"
                    :disabled="!!newReport.id"
                >
                    Submit comment
                </b-button>

                <b-button
                    variant="secondary"
                    @click="$bvModal.hide('comment_profile_modal')"
                >
                    Cancel
                </b-button>
            </template>
        </b-modal>
<!--      <transition name="fade">
        <b-button id="pagetop" style="position: fixed; right: 30px; bottom: 30px;" v-show="scY > getPageHeight()" @click="toTop">
          Back To Top
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 28" fill="none"
               stroke="#ffffff"
               stroke-width="3" stroke-linecap="square" stroke-linejoin="arcs">
            <path d="M18 15l-6-6-6 6"/>
          </svg>
        </b-button>
      </transition> -->
      <back-to-top/>
    </div>
</template>

<script>
import { ApiError } from "api/base"
import { getManufacturers, saveManufacturer, approvalStates, deleteManufacturer } from "api/admin/manufacturers"
import { mapActions, mapGetters } from "vuex"
import AdminPaginationMixin from "../../../mixins/admin_pagination.js"
import { getReports, saveReport } from "api/admin/reports"
import BackToTopMixin from "mixins/back_to_top.js"
import BackToTop from "components/shared/back-to-top"

export default {
    components: {
        BackToTop
    },
    mixins: [AdminPaginationMixin],
    // , BackToTopMixin],
    data () {
        return {
            paginationDisable: false,
            currentTab: 0,
            items: [],
            totalItems: 0,
            itemsPerPage: 500,
            sortBy: "",
            count: 0,
            sortDesc: null,
            allFields: [
                { key: "count", label: "#", sortable: true, class: "column-left", thStyle: { width: "50px"} },
                { key: "status", label: "Type", sortable: true, class: "column-left", thStyle: { width: "100px"} },
                { key: "id", label: "ID", sortable: true, class: "column-left", thStyle: { width: "100px"} },
                { key: "company_name", label: "Company", sortable: true, class: "column-left", thStyle: { width: "400px"} },
                { key: "site", label: "URL", sortable: true, class: "column-left", thStyle: { width: "200px"} },
                { key: "country", label: "Country", sortable: true, class: "column-center", thStyle: { width: "150px"} },
                { key: "state", label: "ST/PR", sortable: true, class: "column-center", thStyle: { width: "150px"} },
                { key: "city", label: "City", sortable: true, class: "column-center", thStyle: { width: "150px"} },
                { key: "zip", label: "Zip", sortable: true, class: "column-center", thStyle: { width: "150px"} },
                { key: "phone", label: "Phone", sortable: false, class: "column-right", thStyle: { width: "150px"} },
                { key: "primary_sub_division_first_two_digit_code", label: "CSI", sortable: true, class: "column-center", thStyle: { width: "150px"} },
                { key: "profile_state", label: "Status", sortable: true, class: "column-center", thStyle: { width: "200px"} },
                // { key: "profile_state", label: "Profile State", sortable: true, class: "column-center" },
                { key: "approved_at", label: "Approve", sortable: true, class: "column-center", thStyle: { width: "100px"} },
                { key: "live_at", label: "Live", sortable: true, class: "column-center", thStyle: { width: "100px"} },
            ],
            approvalFields: [
                { key: "id", label: "ID", sortable: true, class: "column-left", thStyle: { width: "100px" } },
                { key: "created_at", label: "Created", sortable: true, class: "column-center", thStyle: { width: "100px" } },
                { key: "action_date", label: "Action Date", sortable: true, class: "column-center", thStyle: { width: "100px" } },
                { key: "company_name", label: "Company", sortable: true, class: "column-left", thStyle: { width: "400px" } },
                { key: "site", label: "Web", sortable: false, class: "column-right", thStyle: { width: "200px" } },
                { key: "owner_name", label: "Administrator", sortable: false, class: "column-right", thStyle: { width: "100px" } },
                { key: "owner_email", label: "Administrator Email", sortable: false, class: "column-right", thStyle: { width: "200px" } },
                { key: "owner_phone", label: "Phone Number", sortable: false, class: "column-right", thStyle: { width: "100px" } },
                { key: "country", label: "Country", sortable: false, class: "column-right", thStyle: { width: "50px" } },
                { key: "state", label: "ST/PR", sortable: false, class: "column-right", thStyle: { width: "50px" } },
                { key: "primary_sub_division_first_two_digit_code", label: "CSI", sortable: false, class: "column-right", thStyle: { width: "100px" } },
                { key: "approval", label: "Actions", sortable: false, class: "column-center", thStyle: { width: "400px" } },
            ],
            approvalFieldsWithoutActions: [
                { key: "id", label: "ID", sortable: true, class: "column-left", thStyle: { width: "100px" } },
                { key: "created_at", label: "Created", sortable: true, class: "column-center", thStyle: { width: "100px" } },
                { key: "action_date", label: "Action Date", sortable: true, class: "column-center", thStyle: { width: "100px" } },
                { key: "company_name", label: "Company", sortable: true, class: "column-left", thStyle: { width: "400px" } },
                { key: "site", label: "Web", sortable: false, class: "column-right", thStyle: { width: "200px" } },
                { key: "owner_name", label: "Administrator", sortable: false, class: "column-right", thStyle: { width: "100px" } },
                { key: "owner_email", label: "Administrator Email", sortable: false, class: "column-right", thStyle: { width: "200px" } },
                { key: "owner_phone", label: "Phone Number", sortable: false, class: "column-right", thStyle: { width: "100px" } },
                { key: "country", label: "Country", sortable: false, class: "column-right", thStyle: { width: "50px" } },
                { key: "state", label: "ST/PR", sortable: false, class: "column-right", thStyle: { width: "50px" } },
                { key: "primary_sub_division_first_two_digit_code", label: "CSI", sortable: false, class: "column-right", thStyle: { width: "100px" } },
                // { key: "approval", label: "Actions", sortable: false, class: "column-center", thStyle: { width: "400px" } },
            ],
            approvalStates: [undefined, "application", "pre-approved", "submitted", "published", "denied", "claimed", "unclaimed"],
            approvalStateCounter: {
                all: 0,
                application: 0,
                preApproved: 0,
                submitted: 0,
                published: 0,
                denied: 0,
                claimedProfiles: 0,
                unclaimedProfiles: 0
            },
            newReport:{
                body: null,
                bodyState: null,
                topics: [],
                report_type: 'initial'
            },
            selectedManufacturer: null
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),

        fields () {
            if(this.currentTab === 0){
                return this.allFields    
            }else if(this.currentTab === 4){
                return this.approvalFieldsWithoutActions
            }else{
                return this.approvalFields
            }
        },

        allTab () {
            return `All (${this.approvalStateCounter.all})`
        },

        applicationsTab () {
            return `Applications (${this.approvalStateCounter.application})`
        },

        preApprovedTab () {
            return `Pre-approved (${this.approvalStateCounter.preApproved})`
        },

        submittedTab () {
            return `Submitted (${this.approvalStateCounter.submitted})`
        },

        publishedTab () {
            return `Published (${this.approvalStateCounter.published})`
        },

        deniedTab () {
            return `Denied (${this.approvalStateCounter.denied})`
        },

        claimedTab () {
            return `Claimed (${this.approvalStateCounter.claimedProfiles})`
        },

        unClaimedTab () {
            return `Unclaimed (${this.approvalStateCounter.unclaimedProfiles})`
        },
    },

    watch: {
        ["$route"] () {
            this.loadManufacturerProfiles()
        },

        sortBy (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortBy: newValue,
                sortDesc: this.sortDesc
            })
            this.$router.push({ name: this.$route.name, query })
        },

        sortDesc (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortDesc: newValue,
                sortBy: this.sortBy
            })
            this.$router.push({ name: this.$route.name, query })
        },

        currentTab () {
            this.loadManufacturerProfiles(1)
        },
    },

    mounted () {
        this.loadManufacturerProfiles();
        window.addEventListener('scroll', this.handleScroll);
    },

    methods: {
        ...mapActions("account", ["adminSignout"]),

        onSubmitNewComment () {
            console.log({
                reportable_type: "ManufacturerProfile",
                reportable_id: this.selectedManufacturer,
                body: this.newReport.body,
                topics: this.newReport.topics.join(', '),
                report_type: 'commented'
            });
            saveReport(this.adminToken ,{
                reportable_type: "ManufacturerProfile",
                reportable_id: this.selectedManufacturer,
                body: this.newReport.body,
                topics: this.newReport.topics.join(', '),
                report_type: 'commented'
            }).then(result => {
                this.$bvModal.hide("comment_profile_modal");
                this.onRevert(this.selectedManufacturer);
            }).catch(error => this.reportError("Unable to post report", error))
        },

        onSubmitNewReport () {
            console.log({
                reportable_type: "ManufacturerProfile",
                reportable_id: this.selectedManufacturer,
                body: this.newReport.body,
                topics: this.newReport.topics.join(', '),
            });
            saveReport(this.adminToken ,{
                reportable_type: "ManufacturerProfile",
                reportable_id: this.selectedManufacturer,
                body: this.newReport.body,
                topics: this.newReport.topics.join(', '),
            }).then(result => {
                this.$bvModal.hide("denied_reason_modal");
                this.onDeny(this.selectedManufacturer);
            }).catch(error => this.reportError("Unable to post report", error))
        },
        
        onReportProfile (id) {
            this.selectedManufacturer = id;
            this.newReport.body = null
            this.newReport.bodyState = null
            this.newReport.topics = []
            this.newReport.id = null
            this.newReport.report_type = 'initial'
            this.$bvModal.show("denied_reason_modal")
        },
        
        onCommentProfile (id) {
            this.selectedManufacturer = id;
            this.newReport.body = null
            this.newReport.bodyState = null
            this.newReport.topics = []
            this.newReport.id = null
            this.newReport.report_type = 'initial'
            this.$bvModal.show("comment_profile_modal")
        },

        getActionDate (row) {
            switch(this.currentTab){
            case 1:
                return row.item.created_at
                break;
            case 2:
                return row.item.pre_approved_action_date
                break;
            case 3:
                return row.item.submitted_action_date
                break;
            case 4:
                return row.item.published_action_date
                break;
            case 5:
                return row.item.denied_action_date
                break;
            case 6:
                return row.item.claimed_action_date
                break;
            default:

            }
        },

        loadApprovalStates () {
            approvalStates(this.adminToken)
                .then(result => {
                    this.approvalStateCounter.all = result.all
                    this.approvalStateCounter.application = result.application
                    this.approvalStateCounter.preApproved = result.pre_approved
                    this.approvalStateCounter.submitted = result.submitted
                    this.approvalStateCounter.published = result.published
                    this.approvalStateCounter.denied = result.denied
                    this.approvalStateCounter.claimedProfiles = result.claimed_profiles
                    this.approvalStateCounter.unclaimedProfiles = result.unclaimed_profiles
                })
        },

        onSearch () {
            this.loadManufacturerProfiles();
        },
        
        linkGen () {
            return {
                path: this.$route.path,
                query: Object.assign({}, this.$route.query)
            }
        },

        loadManufacturerProfiles (page = null) {
            // this.currentPage = page || this.$route.query.page || 1
            this.items = [];
            this.sortBy = this.$route.query.sortBy
            this.sortDesc = this.$route.query.sortDesc
            this.paginationDisable = true;
            getManufacturers(this.adminToken, {
                page: this.currentPage,
                per_page: this.itemsPerPage,
                sortBy: this.sortBy,
                sortDesc: this.sortDesc,
                approvalState: this.approvalStates[this.currentTab],
                query: this.$route.query.query
            })
                .then(result => {
                    // this.currentPage = result.meta.page
                    this.totalItems = result.meta.total_count
                    this.lastPage = result.meta.total_pages
                    this.itemsPerPage = result.meta.per_page
                    this.items.splice(0, this.items.length, ...result.data)
                    this.paginationDisable = false;
                })
                .catch(error => {
                    if (error instanceof ApiError && error.status === 401) {
                        // Not authorized
                        this.adminSignout(null)
                        this.$router.go()
                    } else {
                        console.log("Error", error)
                    }
                })
            this.loadApprovalStates()
        },

        onClickRow (item) {
            this.$router.push({
                name: "admin-edit-manufacturer",
                params: { id: item.id }
            })
        },

        onApprove (item) {
            const stage = this.currentTab === 1 ? "pre-approved" : this.currentTab === 2 ? "submitted" : this.currentTab === 3 ? "published" : "published"
            if(this.approvalStates[this.currentTab]==='claimed'){
                approveClaimRequests(this.adminToken, item.claim_requests.map(m => m.id) )
                    .then(() => {
                        this.loadClaimRequests()
                        this.$bvToast.toast("Succeeded!", {
                            title: "Saved.",
                            variant: "success",
                            autoHideDelay: 5000
                        })
                    })
                    .catch(error => {
                        if (error instanceof ApiError && error.status === 401) {
                            // Not authorized
                            this.adminSignout(null)
                            this.$router.go()
                        } else {
                            console.log("Error", error)
                        }
                    })
            }else{
                saveManufacturer(this.adminToken, {
                    id: item.id,
                    profile_state: stage
                })
                    .then(() => {
                        this.loadManufacturerProfiles()
                    })
                    .catch(error => this.reportError("Unable to approve", error))
            }
        },
        
        onRevert (id) {
            // const stage = this.currentTab === 1 ? "first" : this.currentTab === 2 ? "second" : "third"
            const stage = this.currentTab === 1 ? "first-saved" : this.currentTab === 2 ? "application" : "pre-approved"
            // const stage = 'denied'
            saveManufacturer(this.adminToken, {
                id,
                profile_state: stage
            })
                .then(() => {
                    this.$bvToast.toast("Comment Succeeded.", {
                                    title: "Success",
                                    variant: "success",
                                    autoHideDelay: 5000
                                })
                    this.loadManufacturerProfiles()
                })
                .catch(error => this.reportError("Unable to Revert", error))
        },

        onDeny (id) {
            // const stage = this.currentTab === 1 ? "first-saved" : this.currentTab === 2 ? "application" : "pre-approved"
            const stage = 'denied';
            saveManufacturer(this.adminToken, {
                id,
                profile_state: stage
            })
                .then(() => {
                    this.$bvToast.toast("Denied Succeeded.", {
                                    title: "Success",
                                    variant: "success",
                                    autoHideDelay: 5000
                                })
                    this.loadManufacturerProfiles()
                })
                .catch(error => this.reportError("Unable to deny", error))
        },

        onDelete (item) {
            const message = `Are you sure you want to delete “${ item.company_name }”? This manufacturer will be deleted immediately. You can't undo this action.`

            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Confirm",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
                .then(isConmanufacturered => {
                    if (isConmanufacturered) {
                        deleteManufacturer(this.adminToken, item)
                            .then(() => {
                                // this.$bvToast.toast("Manufacturer deleted.", {
                                //     title: "Success",
                                //     variant: "success",
                                //     autoHideDelay: 5000
                                // })
                                this.$nextTick(() => {
                                    // this.$router.push({ name: "admin-manufacturers" })
                                    this.loadManufacturerProfiles();
                                })
                            })
                            .catch(error => {
                                this.$bvToast.toast(error.message, {
                                    title: "Unable to delete",
                                    variant: "danger",
                                    autoHideDelay: 5000
                                })
                            })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unknown error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        reportError (title, error) {
            let fullMessage = error.message
            if (error.attributes) {
                let attributeErrors = []
                for (const attribute of Object.keys(error.attributes)) {
                    attributeErrors.push(...error.attributes[attribute])
                }
                if (attributeErrors.length !== 0) {
                    fullMessage += ": " + attributeErrors.join(", ")
                }
            }
            this.$bvToast.toast(fullMessage, {
                title,
                variant: "danger",
                autoHideDelay: 5000
            })                    
        }
    }
}
</script>
<style lang="scss">
#index-table {
    th {
        white-space: nowrap !important;

        div {
            white-space: nowrap !important;            
        }
    }
    tbody {
        td {
            line-breaK: anywhere;
        }
    }
}
.nav-link {
    padding: 0.5rem !important;
}
</style>
<template>
    <b-modal
        :id="id"
        modal-class="become-member-modal"
        hide-header
        hide-footer
        centered
        style="padding: 38px 22px 34px 22px;"
    >
        <a
            class="close-button"
            href="#"
            @click.stop.prevent="onClose"
        >
            <b-img
                :src="require('assets/images/modal-close.png')"
                alt="Fluid-grow image"
                width="16"
                height="16"
            />
        </a>

        <h1 style="margin-bottom: 1rem;">
            <b-img
                :src="require('assets/images/logo-small.png')"
                alt="Fluid-grow image"
                width="38"
                height="35"
            />
            {{ title }}
        </h1>


        <!-- <hr> -->
        <div class="content">
            <p>
                We believe in the necessity of and the art of building product search. Now for the first time online, Designguide presents the largest and most comprehensive Building Product Digital Library showcasing the leading manufacturers in North America.
            </p>
            <br>
            <p>
                If you are familiar with using designguide for free, you are able to continue using the service as a Visitor with<br>no log in or registration required.
            </p>

            <br>

            <b-button
                variant="outline-primary"
                squared
                block
                @click="$bvModal.hide(id)"
            >
                Continue as a visitor
            </b-button>

            <br>

            <p>
                <!-- However, for a more substantial experience, we are now offering a new subscription Membership, Members a new suite of services + features plus a deep dive into our extended Building Product Library featuring over 12,000 manufacturers. -->
                However, for a more substantial experience, we are now offering a new subscription Membership. Members receive a new suite of services and features, plus a deep dive into our extended Building Product Library featuring over 12,000 manufacturers!
            </p>

            <br>

            <b-button
                variant="outline-primary"
                squared
                block
                @click="onSubscription"
            >
                Tell me more about membership
            </b-button>

            <br>

            <p class="subscriber">
                {{ subscriberMessage }}
            </p>

            <p
                v-show="!!errorMessage"
                class="error"
            >
                {{ errorMessage }}
            </p>

            <b-form @submit.stop.prevent="onSubmit">
                <b-form-input
                    v-model="email"
                    type="email"
                    placeholder="Email Address"
                    required
                    autocomplete="off"
                    autocapitalize="none"
                    autocorrect="off"
                />

                <b-form-input
                    v-if="!isForgotPassword"
                    v-model="password"
                    type="password"
                    placeholder="Password"
                    required
                    autocomplete="off"
                    autocapitalize="none"
                    autocorrect="off"
                />

                <p class="forgot-password" v-if="isForgotPassword">
                    Member Log in?
                    <a @click.prevent="signInForm" style="color: #2c4f91; cursor: pointer;">Click Here</a>
                </p>
                <p class="forgot-password" v-else>
                    Forgot your password?
                    <a @click.prevent="forgotYourPassword" style="color: #2c4f91; cursor: pointer;">Click Here</a>
                </p>

                <b-button
                    type="button"
                    variant="primary"
                    squared
                    block
                    @click="submitForgotPassword"
                    v-if="isForgotPassword"
                >
                    Submit
                </b-button>

                <b-button
                    v-else
                    type="submit"
                    variant="primary"
                    squared
                    block
                >
                    Member Log In
                </b-button>
            </b-form>

            <p class="non-subscriber">
            <!-- If you are not a Member and want to find out more about our Membership Subscription program, please click below -->
                Thank you!<br>Team Designguide
            </p>

<!--             <b-button
                variant="outline-primary"
                block
                @click="onSubscription"
            >
                Membership Info
            </b-button> -->
        </div>
        <!-- <h5></h5> -->
        <!--  -->
        <!-- <a href="" @click.prevent.end="onLoginClicked">Please log in here</a> -->
    </b-modal>
</template>

<script>
import { mapActions } from "vuex"

export default {
    props: {
        type: {
            type: String,
            default: "becomeAMember",
            validator: value => [
                "becomeAMember"
            ].indexOf(value) !== -1
        },
        id: {
            type: String,
            default: "become_a_member",
        }
    },

    data () {
        return {
            isForgotPassword: false,
            email: null,
            password: null,
            passwordConfirmation: null,
            currentPassword: null,
            errorMessage: null,
            titles: {
                becomeAMember: "Wecome to the new Designguide"
            },
            visitingAdmin: "Welcome back Admin, please Log In here",
            visitingSubscriber: "Welcome back Member, please Log In here",
            otherSubscriber: "Already a Member? Please Log in here",

        }
    },

    computed: {
        title () { return this.titles[this.type] },
        subscriberMessage () { 
            if(this.type === "admin"){
               return this.visitingAdmin
               
            }
            else if (this.type === "visiting") {
                return this.visitingSubscriber
            }
            else {
                return this.otherSubscriber
            }
        }
    },

    methods: {
        ...mapActions("account", ["signin", "sendResetPassword"]),

        submitForgotPassword () {
            this.sendResetPassword({
                email: this.email
                }).then(() => {
                    this.$bvToast.toast("Please find the reset password email in your inbox!", {
                        title: "Email send!",
                        variant: "success",
                        autoHideDelay: 5000
                    });
                    window.location.reload();
                }).catch(error => {
                this.$bvToast.toast(error.message, {
                    title: "Error",
                    variant: "danger",
                    autoHideDelay: 5000
                })
            })
        },

        forgotYourPassword () {
            this.isForgotPassword = true;
        },

        signInForm () {
            this.isForgotPassword = false;
        },

        onClose () {
            console.log("admin clicked....", this.type)
            this.$bvModal.hide(this.id)
        },

        onSubmit () {
            this.signin({ email: this.email, password: this.password })
                .then(() => {
                    this.$bvModal.hide(this.id)
                    this.$forceUpdate();
                    this.$router.go();
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                    this.errorMessage = error.message
                })
        },

        onSubscription () {
            this.$bvModal.hide(this.id)
            this.$router.push({ name: "become-a-member" })
        },
    },
}
</script>

<style lang="scss" scoped>
    .close-button {
        position: absolute;
        top: 18px;
        right: 22px;

        &:hover {
            opacity: 0.7;
        }
    }

    h1 {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.65;
        color: #000;
        text-align: center;
        margin-bottom: 35px
    }

    hr {
        margin-bottom: 42px;
        border-top: 2px solid #979797;
    }

    .content {
        margin: 0 36px;

        .btn {
            text-transform: uppercase;
        }

        .b-button {
            text-transform: uppercase;
        }

        .btn-primary {
            text-transform: uppercase;
        }


    }
    .btn {
            text-transform: capitalize;
        }

        .b-button {
            text-transform: capitalize;
        }

        .btn-primary {
            text-transform: capitalize;
        }

    p {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.29;
        color: #000;
        text-align: center;
        margin: 0;

        

        &.subscriber {
            font-style: italic;
            margin-bottom: 22px;
        }

        &.error {
            color: #ff8888;
            margin-bottom: 10px;
        }

        &.forgot-password {
            font-style: italic;
            margin-top: 4px;
            margin-bottom: 18px;
        }

        &.non-subscriber {
            margin-top: 35px;
            margin-bottom: 22px;
        }

        a {
            color: #225a90;
            text-decoration: underline;
        }
    }
</style>
